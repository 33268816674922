<template>
    <div class="mt-3">

        <v-data-table :headers="gainLossTableFields"
                      :items="chartData"
                      :item-class="getTransactionRowBackground"
                      :hide-default-footer="true"
                      :disable-pagination="true"
                      fixed-header
                      height="30vh"
                      class="elevation-5 mb-5 secondary"
                      dark>
        </v-data-table>

    </div>
</template>

<script>

    export default {
        name: 'GainLossTableChart',

        props: {
            TaxRecords: {
                type: Array,
                default: () => [],
            }
        },

        /* COMPONENT DATA  */
        data() {
            return {
                gainLossTableFields: [
                    { value: 'asset', text: "Asset", sortable: false, class: 'centeredCell' },
                    { value: 'gain', text: "Total Gain/Loss", sortable: false, class: 'centeredCell' }
                ],
            };
        },

        /* COMPONENT METHODS  */
        methods: {

            getTransactionRowBackground(record) {
                var rowClass = "recordNeutralStyle";
                if (record.gain > 0)
                    rowClass = "recordGainStyle";
                else if (record.gain < 0)
                    rowClass = "recordLossStyle";
                return rowClass;
            },
           
        },

        /* COMPUTED PROPERTIES */
        computed: {
          
            allAssets() {
                //Retrieve a list of distinct assets with transactions
                if (this.TaxRecords.length > 0) {
                    var assets = [...new Set(this.TaxRecords.map(a => a.assetName).sort())];
                    return assets;
                }
                else
                    return [];
            },

            chartData() {

                var tableData = [];
                for (var x = 0; x < this.allAssets.length; x++) {
                    var asset = this.allAssets[x];
                    var total = this.TaxRecords.filter(x => x.assetName == asset).reduce((n, { gain }) => n + parseFloat(gain), 0);

                    var summary =
                    {     
                        "asset": asset,
                        "gain": total.toFixed(2)
                    };

                    tableData.push(summary);
                }

                return tableData;
            }
        }
    };
</script>